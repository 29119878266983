import { createApp } from 'vue';
import Teaser, { type TeaserData } from '@lmt-rpb/Teaser/Teaser.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { mountReplace } from '../utils/mountUtils';

const initImageTeaser = (teaserData: TeaserData[], dataset: DOMStringMap, container: Element) => {
	const app = createApp(Teaser, {
		items: teaserData,
		headerProps: {
			title: dataset.title,
			subtitle: dataset.subtitle,
			mobileTitle: dataset.mobileTitle,
			mobileSubtitle: dataset.mobileSubtitle,
			moreText: dataset.linkText,
			titleAttribute: (dataset.mobileTitle || dataset.title) || '',
			link: dataset.link,
		},
		currency: getLocaleString('currency'),
	});

	mountReplace(app, container);
};

const imageTeaserContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.image-teaser__container');

imageTeaserContainers.forEach((container) => {
	const imageTeaserElements: NodeListOf<HTMLElement> = container.querySelectorAll('.image-teaser');
	const imageTeaserData: TeaserData[] = [];
	imageTeaserElements.forEach((el) => {
		if (!el.dataset.text || !el.dataset.link || !el.dataset.image) {
			return;
		}

		const data = {
			text: el.dataset.text,
			link: el.dataset.link,
			price: el.dataset.price ? Number(el.dataset.price.replace('.', '')) : 0,
			image: el.dataset.image,
		};

		imageTeaserData.push(data);
	});

	if (imageTeaserData.length > 1) {
		initImageTeaser(imageTeaserData, container.dataset, container);
	}
});

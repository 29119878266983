<template>
	<a
		class="rpb_hotelbox"
		:class="boxClass"
		:href="hotel.URL"
		:title="hotel.Name"
		@mousedown="fireSelectPromotionEvent($event)"
	>
		<div class="rpb_hotelbox__image">
			<img
				:alt="hotel.Name"
				:data-srcset="images.s + ' 330w, ' + images.m + ' 513w'"
				:data-src="images.m"
				src="data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw=="
				class="rpb_hotelbox__image rpb_lazy"
			/>
			<BookmarkHotelButton
				:item="item"
				class="rpb_hotelbox__bookmark"
				@click.prevent
			/>
		</div>
		<div class="rpb_hotelbox__subline">
			<span class="rpb_hotelbox__region">
				<span v-if="isRegionOrCity">{{ city.CityName }}</span>
				<span v-else>{{ region.RegionName }}</span>
			</span>
			<span
				v-if="item.Ratings.Overall >= MIN_RATING_FOR_DISPLAY"
				class="rpb_hotelbox__rating"
			>
				{{ overallRating }}
			</span>
		</div>
		<h3
			class="rpb_hotelbox__hotel-name"
			:title="hotel.Name"
		>
			{{ hotel.Name }}
		</h3>
		<HotelStars
			:show-suns="showSuns"
			:rating="hotel.Category"
			class="hotelrating--height"
		/>
		<ul
			v-if="!deal || (deal && props.hotelgrid)"
			class="rpb_hotelbox__text"
		>
			<li>{{ travelDuration }} Tage</li>
			<li>{{ board }}</li>
			<template v-if="hasOutboundFlight">
				<li>Flug ab {{ departureAirportTown }}</li>
			</template>
		</ul>
		<ul
			v-else-if="deal"
			class="rpb_hotelbox__text"
		>
			<li>{{ country.CountryName }}</li>
			<li>{{ board }}</li>
			<li>{{ travelDuration }} Tage</li>
			<li v-if="hasOutboundFlight">Flug ab {{ departureAirportTown }}</li>
			<li v-if="offer.StartDate && departureAirportTown">Abflug: {{ convertDateHelper(offer.StartDate, domain) }}</li>
		</ul>
		<div class="rpb_hotelbox__footer">
			<GroupedPriceInfo
				:currency="currencySymbol"
				:total-price="totalPrice"
				:subtext="priceTypeWording"
				:prefix="travelersLabel"
				:discount="
					showDiscount && priceAverage
						? {
							referencePrice: null,
							referencePriceTotal: {
								avgPrice: priceAverage,
							},
							relativeDiscountPercent: {
								toAvgPrice: dealFactor,
							},
						}
						: undefined
				"
			>
				<template #tooltip>
					<span class="rpb_hotelbox__tooltip-content">{{ tooltipText }}</span>
				</template>
			</GroupedPriceInfo>
		</div>
	</a>
</template>

<script lang="ts" setup>
import { getRatingCombined } from '@utils/utils';
import { convertDateHelper } from '@utils/dateUtils';
import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import GroupedPriceInfo from '@lmt-rpb/Molecules/GroupedPriceInfo/GroupedPriceInfo.vue';
import BookmarkHotelButton from '@lmt-rpb/BookmarkHotelButton/BookmarkHotelButton.vue';
import { computed, toRef } from 'vue';
import PromotionAnalytics from '@/js/modules/analytics/PromotionAnalytics';
import type { ItemType, OfferType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { extractTravelTypeFromOffer } from '@/js/utils/offerUtils';
import { Board } from '@/js/data/board-types';
import { HotelTransformService } from '@/components/HotelGrid/hotelTransformService';
import { useHistoricalPriceData } from '../../composables/discount';
import type { ClientCurrency } from '@global-js/constants';
import { getPriceTypeWording, getTotalPrice } from '@/js/utils/priceUtils';
import { useTravelers } from '../../composables/useTravelers';
import { useDiscount } from '../../composables/discount';

const MIN_RATING_FOR_DISPLAY = 4.2;

interface Props {
	deal?: boolean;
	item: ItemType;
	currencySymbol: ClientCurrency;
	hotelgrid?: boolean;
	showSuns: boolean;
	domain?: string;
	slider: boolean;
	promotionName: string;
	bestRating?: number;
	index: number;
	hasPromotionAnalytics?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	deal: false,
	showSuns: false,
	hotelgrid: false,
	domain: 'AT',
	slider: true,
	bestRating: 6,
	hasPromotionAnalytics: true,
});

const hotel = computed(() => props.item.Hotel);
const region = computed(() => props.item.Location.Region);
const country = computed(() => props.item.Location.Country);
const city = computed(() => props.item.Location.City);

const offer = computed(() => extractTravelTypeFromOffer(props.item) as OfferType);

const hasOutboundFlight = computed(() => !!offer.value.Flight?.OutboundFlight);
const departureAirportTown = computed(() => offer.value.Flight?.OutboundFlight.AirportName);

const board = computed(() => Board[offer.value.Board]);

const wasPrice = computed(() => props.item.WasPrice);

const dealFactor = computed(() => props.item.WasPrice?.relativeDiscountPercent?.toAvgPrice ?? 0);

const overallRating = getRatingCombined(props.item.Ratings.Overall);
const storeTravelers = computed(() => ({
	adult: props.item.TravellerAmount?.Adults ?? 2,
	children: props.item.TravellerAmount?.Children ?? [],
}));

const { hasDiscount, priceAverage } = useHistoricalPriceData(wasPrice);

const { tooltipText } = useDiscount(
	toRef(() => props.item.DiscountedPrice),
	props.item?.Pricing?.Total?.Amount
);

const { travelersLabel } = useTravelers(storeTravelers);

const priceTypeWording = computed((): string => {
	const price = props.item.Pricing.Single;
	const travelers = {
		adult: props.item.TravellerAmount?.Adults ?? 2,
		children: props.item.TravellerAmount?.Children ?? [],
	};
	return getPriceTypeWording({
		type: props.item.Pricing?.Type,
		perPersonPrice: price.Converted ?? price?.Amount,
		currency: props.currencySymbol,
		travelers,
	});
});
const showDiscount = computed(() => dealFactor.value && dealFactor.value < 100 && hasDiscount.value && priceAverage.value);

const totalPrice = computed(() => getTotalPrice(props.item.Pricing));

const travelDuration = computed(() => offer.value.BetweenDeparturesDuration ?? offer.value.OvernightStays);

const images = computed(() => HotelTransformService.generateImageUrls(hotel.value.IffCode.toString()));

const containsBodyClass = (bodyClass: string): boolean => document.body.classList.contains(bodyClass);

const isRegionOrCity = computed(
	(): boolean => containsBodyClass('page_region') || containsBodyClass('page_city') || containsBodyClass('page_topic_topregion')
);

const boxClass = computed(() => ({
	'rpb_hotelbox--deal-list': props.deal && !props.slider,
	'rpb_hotelbox--slider': props.slider,
}));

const fireSelectPromotionEvent = (event: MouseEvent): void => {
	if (!props.hasPromotionAnalytics) {
		return;
	}
	const buttonType = event?.button;

	// Firing GA4 event for left and middle mouse button (works on desktop and mobile)
	if (buttonType === 0 || buttonType === 1) {
		const creativeName = dealFactor.value ? 'Restplätze' : 'Topseller';
		const promotionAnalytics = PromotionAnalytics.fromItemType(
			creativeName,
			props.promotionName,
			props.item,
			props.currencySymbol,
			props.index
		);
		promotionAnalytics.fireSelectPromotion();
	}
};
</script>

<style lang="scss" scoped>
/* Mobile */
.rpb_hotelbox {
	display: inline-block;
	padding: 0 0 1.4rem;
	transition: box-shadow 0.25s ease;
	box-shadow: $box-shadow-2;
	color: $color-text;
	font-size: $font-small-1;
	line-height: 1.7;
	text-decoration: none;
	text-align: left;
	border-bottom: 0.2rem solid transparent;
	position: relative;
	max-width: 46.8rem;
	border-radius: $border-radius-large;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		box-shadow: $box-shadow-2-hover;
		outline: 0;
		color: inherit;
	}

	a,
	a:hover,
	a:active {
		color: $color-white;
		text-decoration: none;
		display: inline-block;
	}

	img {
		width: 100%;
		border-top-left-radius: $border-radius-large;
		border-top-right-radius: $border-radius-large;
	}

	&__tooltip-content {
		text-align: left;
		max-width: 60rem;
		white-space: normal;
		display: block;
	}

	&__image {
		font-size: 0;
		overflow: hidden;
		position: relative;

		&--placeholder_box {
			position: relative;
			background-color: $color-medium-gray;
			padding-bottom: 66.66%;
			height: 0;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	h3 {
		font-family: $font-family;
		color: $color-text;
		font-size: $font-medium-1;
		font-weight: $font-weight-semibold;
		line-height: 3.4rem;
		text-align: left;
		clear: both;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
		overflow: hidden;
		padding: 0 1.6rem;
	}

	p {
		margin: 0;
		padding: 0.5rem 0;
		font-size: $font-small-1;
	}

	.hotelrating--height {
		height: 1.4rem;
		margin-top: 0.4rem;
		margin-bottom: 1rem;
		text-align: left;
		padding: 0 1.6rem;
	}

	&__footer {
		display: flex;
		padding: 0 0.8rem;
		justify-content: flex-end;
		align-items: flex-end;
		gap: 1.2rem;
		margin-top: auto;
	}

	&__text {
		min-height: 6.2rem;
		list-style: none;
		padding: 0 1.6rem 0.8rem;
		font-size: 0;
		text-align: left;
		margin: 0;

		li {
			display: inline-block;
			font-size: $font-small-1;
			max-width: 28rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		li::after {
			content: '•';
			color: $color-primary-l1;
			display: inline-block;
			margin: 0 0.5rem;
		}

		li:last-child::after {
			content: '';
		}
	}

	&__specialoffer {
		color: $color-text;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-bottom: 0.2rem;

		&--discount {
			font-size: $font-medium-2;
			line-height: 1;
			font-weight: $font-weight-bold;
			position: relative;
			display: inline-block;
			padding: 0.2rem 1rem 0.2rem 0.5rem;
			overflow: hidden;
			color: $color-white;
			text-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 20%);
		}

		&--discount::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-secondary;
			transform-origin: 100% 0;
			transform: skew(-17deg);
			z-index: -1;
		}

		&--oldprice {
			padding: 0 0 0.1rem;
			font-size: 1.5rem;
			font-weight: $font-weight-semibold;
			text-align: right;
			line-height: 2rem;
			color: $color-primary;
		}
	}

	&--deal-list {
		width: 100%;
		max-width: none;
	}

	&__price {
		display: flex;
		gap: 0.4rem;
		align-items: flex-end;
		flex-direction: row;
		justify-content: flex-end;
		color: $color-text;
		height: 3.6rem;
		line-height: 2.7rem;
		font-size: $font-small-2;
	}

	.rpb_price {
		font-size: $font-large-4;
		line-height: 3.4rem;
		text-align: left;
		display: block;

		:deep(.rpb_price__from-text) {
			font-size: $font-small-2;
			line-height: 2.7rem;
		}

		:deep(.rpb_price__amount) {
			color: $color-extra;
		}
	}
}

.rpb_hotelbox__bookmark {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 0 1rem 1rem;
}

:deep(.bookmark-hotel-button) {
	svg {
		margin-right: 1rem;
		margin-top: 1rem;
		fill: $color-white;
		fill: $color-white-t3;
		stroke: $color-white;
		stroke-width: 0.1rem;
		width: 3.6rem;
		height: 3.6rem;
		transition:
			fill 0.3s,
			stroke 0.3s,
			transform 0.3s;
	}
}

:deep(.bookmark-hotel-button--active) {
	svg {
		fill: $color-extra;
	}
}

.rpb_hotelbox__region {
	font-size: $font-small-2;
	font-weight: $font-weight-semibold;
	color: $color-text-light;
	flex: 1 1 100%;
	overflow: hidden;

	span {
		display: block;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 1rem;
	}
}

.rpb_hotelbox__rating {
	font-size: $font-small-1;
	color: $color-primary;
	font-weight: $font-weight-bold;
	white-space: nowrap;
	height: 2.8rem;
	display: flex;
}

.rpb_hotelbox__subline {
	border-bottom: 0.1rem solid $color-primary-l5;
	display: flex;
	align-items: baseline;
	padding: 0 1.6rem;
	height: 2.9rem;
}

.rpb_hotelbox--slider {
	max-width: 28.4rem;
	width: 28.4rem;
	display: inline-block;

	.rpb_hotelbox__text {
		white-space: normal;
		flex: 1 0 auto;
	}
}

@media (min-width: $breakpoint-mobilelarge) {
	.rpb_hotelbox__footer {
		padding: 0 1.6rem;
	}
}

/* Mobile landscape */
@media (min-width: $breakpoint-mobile) {
	.rpb_hotelbox__specialoffer .rpb_price {
		font-size: $font-large-4;
	}
}

/* Mobile landscape */
@media (min-width: $breakpoint-verysmall) {
	.rpb_hotelbox {
		font-size: $font-small-2;
	}

	.rpb_textbox,
	.rpb_hotelbox__text {
		li {
			max-width: 24rem;
		}
	}

	.rpb_hotelbox__specialoffer .rpb_price {
		font-size: 3rem;
	}
}

/* Tablet  Portrait */
@media (min-width: $breakpoint-small) {
	.rpb_hotelbox {
		&__specialoffer {
			&--oldprice {
				padding-left: 0;
			}
		}

		&__price {
			font-size: $font-medium-3;
		}

		.rpb_price {
			:deep(.rpb_price__from-text) {
				font-size: $font-medium-3;
			}

			:deep(.rpb_price__amount) {
				font-size: 2.8rem;
				line-height: 3.4rem;
			}
		}
	}
}

/* Tablet landscape */
@media (min-width: $breakpoint-medium) {
	.rpb_hotelbox {
		&__footer {
			gap: 1.6rem;
		}

		h3 {
			line-height: 1.7;
		}
	}
}

@media (min-width: $breakpoint-scroller) {
	.rpb_textbox {
		flex: 1 0 auto;
	}

	.rpb_hotelbox--slider {
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		white-space: nowrap;
		-webkit-tap-highlight-color: transparent;

		.rpb_hotelbox__rating {
			span {
				color: $color-text-light;
			}
		}

		.rpb_hotelbox__text {
			white-space: normal;
			line-height: 1.5;
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.rpb_hotelbox {
		&__footer {
			align-items: baseline;
		}

		&__specialoffer {
			flex-direction: row;
			align-items: baseline;
			gap: 0.8rem;
		}

		.rpb_price {
			:deep(.rpb_price__amount) {
				font-size: 3rem;
			}
		}
	}
}
</style>

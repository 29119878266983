import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-field" }

import SearchSuggest from '@lmt-rpb/SearchSuggest/SearchSuggest.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'Search',
  setup(__props, { expose: __expose }) {


const { isMinTablet } = storeToRefs(useBreakpointStore());

__expose({
	SearchSuggest,
	FormField,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchSuggest, {
      inline: !_unref(isMinTablet),
      icon: "search",
      "custom-clear-icon": 'clearSearch'
    }, null, 8 /* PROPS */, ["inline"])
  ]))
}
}

})
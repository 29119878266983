import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "theme-page-search" }

import SearchForm from '@lmt-rpb/SearchForm/SearchForm.vue';
import SearchFormModal from '@lmt-rpb/SearchFormModal/SearchFormModal.vue';
import { computed, onMounted, ref } from 'vue';
import { EventBus } from '@global-js/event-bus';
import { useStore } from '@/components/common/store';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from 'src/store/breakpointsStore';
import type { BackgroundImages } from '@/interfaces/search-form';
import type { ClientType } from '@/interfaces/common';

interface Props {
	client?: ClientType;
	images?: BackgroundImages | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ThemePageSearch',
  props: {
    client: { default: 'at' },
    images: { default: undefined }
  },
  setup(__props: any) {



const searchFormModal = ref<InstanceType<typeof SearchFormModal>>();

const store = useStore();

const { isDesktop } = storeToRefs(useBreakpointStore());

const isHomePage = computed(() => store.state.config.pageType === 'homePage');

const showDesktopForm = computed(() => isDesktop.value || isHomePage.value);

onMounted(() => {
	EventBus.$on('ThemePageSearch:openSearch', () => searchFormModal.value?.openModal());
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (showDesktopForm.value)
      ? (_openBlock(), _createBlock(SearchForm, {
          key: 0,
          client: _ctx.client,
          images: _ctx.images
        }, null, 8 /* PROPS */, ["client", "images"]))
      : _createCommentVNode("v-if", true),
    (!showDesktopForm.value)
      ? (_openBlock(), _createBlock(SearchFormModal, {
          key: 1,
          ref_key: "searchFormModal",
          ref: searchFormModal,
          header: "Suche anpassen",
          "apply-button": "Suchen",
          "cancel-button": "Abbrechen"
        }, null, 512 /* NEED_PATCH */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})
import { createApp } from 'vue';
import BannerTop from '@lmt-rpb/BannerTop/BannerTop.vue';
import { EventBus } from '@global-js/event-bus';
import { mountReplace } from '../utils/mountUtils';
import Cookies from 'js-cookie';

const setCookie = (lifetime: number) => {
	Cookies.set('banner-top', true, {
		expires: lifetime,
		domain: '.' + window.location.host,
		secure: true,
		sameSite: 'strict'
	});
};

const initBannerTop = () => {
	const dataset = document.getElementById('banner_top')?.dataset;
	const bannerShown = Cookies.get('banner-top');

	if (dataset?.show !== '1' || bannerShown === 'true') {
		document.querySelector('section.banner_top')?.remove();
		return;
	}

	const cookieLifetime = dataset?.cookieLifetime ? parseInt(dataset?.cookieLifetime, 10) : 2; // default 2 days

	EventBus.$on('BannerTop:Close', () => {
		setCookie(cookieLifetime);
	});

	const mountPoint = document.querySelector('#banner_top');
	const app = createApp(BannerTop, {
		text: dataset.text,
		subtext: dataset.subtext,
		link: dataset.link,
		show: true,
	});

	if (mountPoint) mountReplace(app, mountPoint);
};

initBannerTop();

<template>
	<div
		class="header-favorite-dropdown"
		:class="{ 'header-favorite-dropdown--show': showDropdown }"
	>
		<div class="header-favorite-list-wrapper">
			<FavoritedHotelList
				:hotels="hotels"
				:show-suns="showSuns"
				@FavoritedHotelList:Delete="removeFavorite"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { isClientGermany } from '@utils/environmentUtils';
import FavoritedHotelList from '@lmt-rpb/FavouritedHotelList/FavoritedHotelList.vue';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import type { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { removeEntryFromFavoritePersistence } from '../../js/modules/bookmark';

interface Props {
	showDropdown: boolean;
	hotels: FavoritedHotel[];
}

withDefaults(defineProps<Props>(), {
	showDropdown: false,
});

const showSuns = isClientGermany();

const removeFavorite = (removeHotel: FavoritedHotel) => {
	const item = BookmarkAnalytics.fromFavoritedHotel([removeHotel]);
	removeEntryFromFavoritePersistence(item);
};
</script>

<style lang="scss" scoped>
.header-favorite-dropdown {
	position: fixed;
	top: 6rem;
	left: 0;
	width: 100%;
	background: $header-bar-bg;
	justify-content: center;
	z-index: 6500;
	opacity: 0;
	visibility: hidden;
	transition:
		visibility 0.2s ease-in,
		opacity 0.2s ease-in;
	overflow: hidden;
}

.header-favorite-dropdown--show {
	visibility: visible;
	opacity: 1;
}

@media (min-width: $breakpoint-small) {
	.header-favorite-dropdown {
		position: relative;
		overflow: visible;
		top: 1rem;
	}

	.header-favorite-list-wrapper {
		position: absolute;
		right: -2.4rem;
		min-width: 46rem;
		max-width: 45.7rem;
		background-color: $color-white;
		border: 0.2rem solid $color-lime-light;
		border-radius: $border-radius-large;
		box-shadow: 0 0.4rem 0.3rem rgb(51 51 51 / 20%);
		display: flex;
	}
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loading__headlines"
}
const _hoisted_2 = {
  key: 2,
  class: "loading__container"
}

import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import axios from 'axios';
import { getLocaleString, isClientGermany } from '@utils/environmentUtils';
import type { ApiDataTypes } from '@components/common/types/index';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { EventBus } from '@global-js/event-bus';
import { computed, onMounted, ref } from 'vue';
import { getHotelListOffer, getDiscounts } from '@services/bestOfferForHotelListService';
import OfferGrid from '@lmt-rpb/OfferGridNew/OfferGridNew.vue';
import { useStore } from '@/components/common/store';
import { HotelTransformService } from '../HotelGrid/hotelTransformService';
import type { ItemType } from '@/interfaces/hotel-list-types/hotel-list-types';
import { getDefaultCurrency } from '@utils/environmentUtils';

interface Props {
	fromPlugin: boolean;
	hotelGridOffers: ItemType[];
	bodyParams: ApiDataTypes;
	hotelOnly: boolean;
	apiUrl: string;
	uniqueKey: string;
	destinationName?: string;
	moreLink: string;
	headerProps: HeaderProps;
	hotelBoxAmount?: number;
	fourColumns?: boolean;
	isAutoGeneratedTopic?: boolean;
	queryString?: string;
	isSlider?: boolean;
}

enum DestinationType {
	Country = 'country',
	Region = 'region',
	City = 'city',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelGrid',
  props: {
    fromPlugin: { type: Boolean },
    hotelGridOffers: {},
    bodyParams: {},
    hotelOnly: { type: Boolean },
    apiUrl: {},
    uniqueKey: {},
    destinationName: { default: '' },
    moreLink: {},
    headerProps: {},
    hotelBoxAmount: { default: 3 },
    fourColumns: { type: Boolean, default: false },
    isAutoGeneratedTopic: { type: Boolean, default: false },
    queryString: { default: '' },
    isSlider: { type: Boolean, default: true }
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const headerPropsClone = ref<HeaderProps>(props.headerProps);
const moreLinkClone = ref<string>(props.moreLink);
const items = ref<ItemType[]>([]);
const error = ref(false);
const loading = ref(false);
const currencySymbol = ref(getDefaultCurrency());
const showSuns = isClientGermany();

const destinationType = computed((): string => {
	const destinationTypes = {
		page_country: 'country',
		page_region: 'region',
		page_city: 'city',
		page_topic_regiongroup: 'country',
		page_topic_topregion: 'region',
	};
	const bodyClassList = document.body.classList;
	let type = '';

	Object.keys(destinationTypes).forEach((pageClass) => {
		if (bodyClassList.contains(pageClass)) {
			type = destinationTypes[pageClass as keyof typeof destinationTypes];
		}
	});

	return type;
});

async function getOffersFromService() {
	loading.value = true;
	return getHotelListOffer(props.apiUrl, props.bodyParams, props.uniqueKey);
}

async function fetchNeustaData() {
	try {
		const adultsCount = props.hotelGridOffers[0]?.TravellerAmount?.Adults ?? store.state.searchMask.travelers.adult;
		const childrenCount = props.hotelGridOffers[0]?.TravellerAmount?.Children ?? store.state.searchMask.travelers.children ?? [];

		const travellerInfo = {
			adult: adultsCount,
			children: childrenCount,
		};
		items.value = await getDiscounts(items.value, travellerInfo);
	} catch (err) {
		// do nothing
	}
}

const updateHeaderPropsClone = (): void => {
	let newListUrl = HotelTransformService.buildListUrl(
		false, // Don't append query string on auto generated 'Urlaub' or 'Reiseziel' pages
		store.state.searchMask?.destination?.id as number,
		destinationType.value as DestinationType,
		props.hotelOnly
	);
	const params = props.queryString ? `?${props.queryString}` : '';
	if (props.isAutoGeneratedTopic) newListUrl = `${newListUrl}${params}`;

	headerPropsClone.value = {
		...props.headerProps,
		link: newListUrl,
	};
	moreLinkClone.value = newListUrl;
};

async function initOfferHotelBoxData() {
	try {
		if (props.fromPlugin) {
			items.value = props.hotelGridOffers;
		} else {
			const hotelListItemResponse = await getOffersFromService();
			items.value = hotelListItemResponse.Offers;
		}
		fetchNeustaData();
	} catch (err) {
		error.value = true;
		if (!axios.isCancel(err)) {
			console.error('Error while init HotelBox data. ', JSON.stringify(err));
		}
	} finally {
		loading.value = false;
	}
}

function initHeader() {
	if (props.fromPlugin) {
		if (props.isAutoGeneratedTopic) {
			updateHeaderPropsClone();
		}
	} else {
		const destinationId = store?.state?.searchMask?.destination?.id;
		if (!destinationId) {
			EventBus.$once('Autocomplete:updatedActiveItem', updateHeaderPropsClone);
		} else {
			updateHeaderPropsClone();
		}
	}
}

onMounted((): void => {
	initHeader();
	initOfferHotelBoxData();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!loading.value && !error.value && items.value.length)
      ? (_openBlock(), _createBlock(OfferGrid, {
          key: 0,
          "item-list": items.value,
          "hotel-grid": true,
          slider: _ctx.isSlider,
          "currency-symbol": currencySymbol.value,
          "show-suns": _unref(showSuns),
          "show-bottom-c-t-a": false,
          "destination-name": _ctx.destinationName,
          "header-props": headerPropsClone.value,
          "more-link": moreLinkClone.value,
          "four-columns": _ctx.fourColumns,
          deal: true
        }, null, 8 /* PROPS */, ["item-list", "slider", "currency-symbol", "show-suns", "destination-name", "header-props", "more-link", "four-columns"]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(SkeletonLoader, {
            "width-unit": 'rem',
            "loader-width": 30,
            "loader-height": 7,
            "border-radius": '0.5rem',
            class: "loading__headline"
          }),
          _createVNode(SkeletonLoader, {
            "width-unit": 'rem',
            "loader-width": 16,
            "loader-height": 3,
            "border-radius": '0.5rem',
            class: "loading__more-link"
          })
        ]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelBoxAmount, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "loading__item"
            }, [
              _createVNode(SkeletonLoader, {
                "width-unit": '%',
                "loader-width": 100,
                "loader-height": 44.6,
                "border-radius": '1.2rem'
              })
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "search-suggest__load-box" }
const _hoisted_2 = {
  key: 0,
  class: "search-suggest__load-box-suggest"
}

import Loading from '@lmt-rpb/Loading/Loading.vue';
import { computed, onBeforeUnmount, ref, onBeforeMount } from 'vue';
import { useStore } from '@/components/common/store';
import * as searchSuggestService from '@services/searchSuggestService';
import type { SuggestionElements } from '@/interfaces/api/v1-search-suggest';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from '../../../../store/breakpointsStore';
import SearchSuggestItem from '@lmt-rpb/SearchSuggestItem/SearchSuggestItem.vue';

interface Props {
	icon?: string;
	customClearIcon?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchSuggest',
  props: {
    icon: { default: '' },
    customClearIcon: { default: undefined }
  },
  setup(__props: any, { expose: __expose }) {

const store = useStore();

const { isMinTablet: isViewPort } = storeToRefs(useBreakpointStore());

const isFocused = ref(false);
const focusIn = () => (isFocused.value = true);
const focusOut = () => {
	dropdown.value?.modal?.input?.blur();
	dropdown.value?.formField?.input?.blur();
	isFocused.value = false;
};



function selectFirst() {
	const url = searchSuggests.value[0]?.url;
	if (url) {
		loadingOnClick.value = true;
		window.location.href = buildUrl(url);
	}
}

const dropdown = ref<InstanceType<typeof DropdownFilterType> | null>(null);

const searchSuggestItemList = ref<InstanceType<typeof SearchSuggestItem>[]>([]);

const term = ref<string>('');

const emptyState = [
	{
		type: '',
		title: '',
		url: '',
		subtitle: '',
	},
];

const searchSuggests = ref<SuggestionElements[]>(emptyState);

const loading = ref(false);

const loadingOnClick = ref(false);

const error = ref<boolean>(false);

const itemListContainer = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const placeholderText = computed((): string => (isFocused.value ? '' : isViewPort.value ? 'Suchen' : 'Website durchsuchen'));

const baseUrl = computed((): string => store.state.config.baseUrl);

const buildUrl = (urlParams: string) => `${baseUrl.value}${urlParams}?term=${encodeURIComponent(term.value)}`;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= 2);

const search = async () => {
	loading.value = true;
	try {
		searchSuggests.value = await searchSuggestService.get(term.value);
	} catch (err) {
		console.error(err);
		error.value = true;
	} finally {
		loading.value = false;
	}
};

const clearSuggestAndClose = (): void => {
	term.value = '';
	searchSuggests.value = [];
};

const onTermChange = (): void => {
	error.value = false;
	if (!minCharCount.value) {
		searchSuggests.value = emptyState;
	} else {
		search();
	}
};

const onInput = (value: string): void => {
	term.value = value;
	onTermChange();
};
const closeModal = () => {
	if (!isViewPort.value) {
		return;
	}
	dropdown.value?.close();
};
const observer = new MutationObserver((mutations) => {
	mutations
		.filter((mutation) => mutation.type === 'attributes')
		.forEach((mutation) => {
			if (mutation.target?.className?.includes('--hidden')) {
				closeModal();
				focusOut();
			}
		});
});

const onUnload = () => {
	loadingOnClick.value = false;
	closeModal();
	focusOut();
};

onBeforeMount(() => {
	observer.observe(document.body, { attributes: true });
	window.addEventListener('unload', onUnload);
	window.addEventListener('pagehide', onUnload);
});
onBeforeUnmount((): void => {
	observer.disconnect();
	window.addEventListener('unload', onUnload);
	window.addEventListener('pagehide', onUnload);
});
__expose({
	input,
	dropdown,
	clearSuggestAndClose,
	term,
	search,
	searchSuggests,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "search-suggest",
    onScrollCapture: focusOut
  }, [
    _createVNode(DropdownFilterType, {
      ref_key: "dropdown",
      ref: dropdown,
      title: '',
      tabindex: "-1",
      class: "autocomplete__dropdown",
      searchable: true,
      placeholder: placeholderText.value,
      "modal-header": 'Webseite durchsuchen',
      icon: _ctx.icon,
      "allow-clear": true,
      "apply-button-text": "Übernehmen",
      "search-term": term.value,
      "with-menu-header": false,
      "emit-on-ok": true,
      "is-focused": isFocused.value,
      "show-toggle-icon": false,
      "is-custom-focus": false,
      "dropdown-visible": minCharCount.value,
      "is-custom-view-port": 'isMinTablet',
      "custom-clear-icon": _ctx.customClearIcon,
      "onDropdownFilterType:clear": clearSuggestAndClose,
      "onDropdownFilterType:abort": clearSuggestAndClose,
      "onDropdownFilterType:KeyEnter": selectFirst,
      "onUpdate:searchTerm": onInput,
      "onDropdownFilterType:focusedOut": focusOut,
      "onDropdownFilterType:focusedIn": focusIn
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          ref_key: "itemListContainer",
          ref: itemListContainer,
          class: "search-suggest__list",
          role: "group",
          "aria-label": "Ergebnisse",
          tabindex: "-1"
        }, [
          _createCommentVNode(" Loading "),
          (loading.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createCommentVNode(" Loading "),
                _renderSlot(_ctx.$slots, "loading", {}, () => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(Loading, {
                      size: "small",
                      class: "search-suggest__loader"
                    })
                  ])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : (error.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createCommentVNode(" Error "),
                  _createCommentVNode(" Error "),
                  _renderSlot(_ctx.$slots, "error", {
                    error: error.value,
                    term: term.value
                  }, () => [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "search-suggest__helper" }, [
                      _createElementVNode("p", null, "Der Server ist im Augenblick nicht erreichbar."),
                      _createTextVNode(" Bitte versuchen Sie es in Kürze erneut. ")
                    ], -1 /* HOISTED */))
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (!searchSuggests.value.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createCommentVNode(" No result "),
                        _renderSlot(_ctx.$slots, "no-result", {}, () => [
                          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "search-suggest__helper" }, "Keine Ergebnisse gefunden.", -1 /* HOISTED */))
                        ])
                      ], 64 /* STABLE_FRAGMENT */))
                    : _renderSlot(_ctx.$slots, "result", { key: 1 }, () => [
                        _createCommentVNode(" Result list "),
                        _createElementVNode("ul", {
                          class: _normalizeClass(["search-suggest__item-list", { 'search-suggest__item-list--loading': loadingOnClick.value }])
                        }, [
                          (loadingOnClick.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                _createVNode(Loading, {
                                  size: "small",
                                  class: "search-suggest__loader"
                                })
                              ]))
                            : _createCommentVNode("v-if", true),
                          _createCommentVNode(" Result item "),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchSuggests.value, (item, key) => {
                            return (_openBlock(), _createBlock(SearchSuggestItem, {
                              key: key,
                              ref_for: true,
                              ref_key: "searchSuggestItemList",
                              ref: searchSuggestItemList,
                              class: _normalizeClass({ 'is-active': key === 0 }),
                              url: buildUrl(item.url),
                              item: item,
                              "onSearchSuggestItem:Loading": _cache[0] || (_cache[0] = ($event: any) => (loadingOnClick.value = true))
                            }, null, 8 /* PROPS */, ["class", "url", "item"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ], 2 /* CLASS */)
                      ])
                ], 64 /* STABLE_FRAGMENT */))
        ], 512 /* NEED_PATCH */)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["placeholder", "icon", "search-term", "is-focused", "dropdown-visible", "custom-clear-icon"])
  ], 32 /* NEED_HYDRATION */))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pill-list__wrapper" }
const _hoisted_2 = { class: "pill-list" }

import Pill from '@lmt-rpb/Pill/Pill.vue';
import Gradient from '@lmt-rpb/Atoms/Gradient/Gradient.vue';
import type { PillType } from './types';

interface Props {
	pills: PillType[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PillList',
  props: {
    pills: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pills, (pill, i) => {
        return (_openBlock(), _createBlock(Pill, {
          key: pill.label,
          label: pill.label,
          url: pill.url,
          title: pill.title || '',
          filled: _ctx.pills.length - 1 == i ? true : false
        }, null, 8 /* PROPS */, ["label", "url", "title", "filled"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(Gradient, {
      height: "6.8",
      width: "4",
      top: "0"
    })
  ]))
}
}

})
import { createApp } from 'vue';
import TravelTypes from '@lmt-rpb/TravelTypes/TravelTypes.vue';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { BubbleData } from '@/interfaces/travel-type-bubble-type';
import { mountReplace } from '../utils/mountUtils';

const initTravelTypeBubbles = () => {
	document.querySelectorAll('.travel-types-inner').forEach((elementToMount) => {
		const travelTypesString = (elementToMount as HTMLElement)?.dataset?.travelTypes;
		if (!travelTypesString) return;
		const bubbleData: BubbleData[] = JSON.parse(travelTypesString);
		const headerData = (elementToMount.parentNode as HTMLElement)?.dataset;
		const headerProps: HeaderProps = {
			title: headerData.header || '',
			subtitle: headerData.subheader || '',
			mobileTitle: headerData.headerMobile || '',
			mobileSubtitle: headerData.subheaderMobile || '',
			moreText: headerData.moreText || '',
			link: headerData.headerLink || '',
			titleAttribute: (headerData.headerMobile || headerData.header) || '',
		};
		const app = createApp(
			TravelTypes,
			{
				items: bubbleData,
				headerProps,
			}
		);
		mountReplace(app, elementToMount);
	});
};

initTravelTypeBubbles();

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["navigation"]
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href", "data-ga-item_id"]
const _hoisted_4 = ["data-src", "alt"]
const _hoisted_5 = { class: "teaser__item-footer" }
const _hoisted_6 = { class: "teaser__item-name" }
const _hoisted_7 = {
  key: 0,
  class: "teaser__item-price"
}
const _hoisted_8 = { class: "teaser__item-price-formatted" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["id"]

import { onMounted, ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import type { Swiper as SwiperType } from 'swiper';
import { register } from 'swiper/element/bundle';
import { addTopMarginToFirstElement } from '@utils/utils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { formatPrice } from '@/js/utils/priceUtils';

export interface TeaserData {
	text: string;
	link: string;
	price: number;
	image: string;
}

interface Props {
	headerProps: HeaderProps,
	items: TeaserData[]
	currency?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Teaser',
  props: {
    headerProps: {},
    items: {},
    currency: { default: 'EUR' }
  },
  setup(__props: any) {

register();



const teaserElement = ref<HTMLElement | null>(null);
const uniqueId = CSS.escape('id-' + uuidv4());
const swiperRef = ref<SwiperType | null>(null);

const showLeftOverlay = ref(false);
const showRightOverlay = ref(true);

const isMouseDown = ref(false);
const isDragging = ref(false);

const swiperScrollClasses = computed(() => [
	'teaser__scroll',
	{ 'teaser__scroll--left': showLeftOverlay.value },
	{ 'teaser__scroll--right': showRightOverlay.value },
]);

const swiperSettings = {
	slidesPerView: 1.25,
	spaceBetween: 15,
	touchRatio: 1,
	breakpoints: {
		544: { // breakpoint-verysmall
			slidesPerView: 2.25,
			touchRatio: 1,
		},
		768: { // breakpoint-small
			slidesPerView: 3,
			touchRatio: 1,
		},
		1200: { // breakpoint-large
			slidesPerView: 4,
			touchRatio: 0,
		}
	}
};

const addMarginTop = (): void => {
	if (teaserElement.value) {
		addTopMarginToFirstElement(teaserElement.value);
	}
};

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (e: MouseEvent): void => {
	setTimeout(() => {
		e.preventDefault();
		e.stopPropagation();

		isMouseDown.value = false;
		isDragging.value = false;
	}, 300);
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const handleSlideChange = (swiperEvent: CustomEvent | undefined) => {
	if (!swiperEvent?.detail[0]) return;

	showLeftOverlay.value = !swiperEvent.detail[0].isBeginning;
	showRightOverlay.value = !swiperEvent.detail[0].isEnd;
};

onMounted(() => {
	addMarginTop();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    ref_key: "teaserElement",
    ref: teaserElement,
    class: "teaser"
  }, [
    _createVNode(ComponentHeader, { "header-props": _ctx.headerProps }, null, 8 /* PROPS */, ["header-props"]),
    _createElementVNode("div", {
      ref_key: "swiperRef",
      ref: swiperRef,
      class: "teaser__scroll-container"
    }, [
      _createElementVNode("swiper-container", _mergeProps(swiperSettings, {
        navigation: {
					nextEl: `#${_unref(uniqueId)}-next`,
					prevEl: `#${_unref(uniqueId)}-prev`
				},
        class: swiperScrollClasses.value,
        onSwiperslidechange: handleSlideChange
      }), [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("swiper-slide", {
            key: index,
            title: item.text,
            class: "swiper-slide"
          }, [
            _createElementVNode("a", {
              href: isDragging.value ? undefined : item.link,
              class: "teaser__item",
              "data-ga-event": "select_content",
              "data-ga-content_type": "Teaser Item",
              "data-ga-item_id": item.text
            }, [
              _createElementVNode("img", {
                class: "teaser__item-image rpb_lazy",
                "data-sizes": "auto",
                "data-src": item.image,
                src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
                alt: item.text,
                onMousedown: onMouseDown,
                onMouseup: onMouseUp,
                onMousemove: onMouseMove
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_4),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(item.text), 1 /* TEXT */),
                (item.price)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _cache[0] || (_cache[0] = _createElementVNode("small", null, "ab ", -1 /* HOISTED */)),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(formatPrice)(item.price, _ctx.currency)), 1 /* TEXT */)
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ], 8 /* PROPS */, _hoisted_3)
          ], 8 /* PROPS */, _hoisted_2))
        }), 128 /* KEYED_FRAGMENT */))
      ], 16 /* FULL_PROPS */, _hoisted_1),
      _createElementVNode("div", {
        id: `${_unref(uniqueId)}-prev`,
        class: "swiper-button-prev"
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "teaser__arai-label" }, "prev", -1 /* HOISTED */)),
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "teaser__prev-icon"
        })
      ], 8 /* PROPS */, _hoisted_9),
      _createElementVNode("div", {
        id: `${_unref(uniqueId)}-next`,
        class: "swiper-button-next"
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "teaser__arai-label" }, "next", -1 /* HOISTED */)),
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "teaser__next-icon"
        })
      ], 8 /* PROPS */, _hoisted_10)
    ], 512 /* NEED_PATCH */)
  ], 512 /* NEED_PATCH */))
}
}

})
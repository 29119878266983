import { createApp } from 'vue';
import Accordion from '@lmt-rpb/Accordion/Accordion.vue';
import { mountReplace } from '../utils/mountUtils';

const initFaqAccordion = () => {
	const faqElements: NodeListOf<HTMLElement> = document.querySelectorAll('.rpb_faq__card');
	faqElements.forEach((el, index) => {
		if (!el.dataset.question || !el.dataset.answer) {
			return;
		}
		const app = createApp(Accordion, {
			title: el.dataset.question,
			bodyText: el.dataset.answer,
			mobileAccordionOnly: el.classList.contains('rpb_faq__card-accordion-mobile'),
			isOpen: index === 0,
		});
		mountReplace(app, el);
	});
};

initFaqAccordion();

<template>
	<a
		v-if="item.title"
		:title="removeBoldTags(item?.title, '')"
		class="search-suggest__item-link"
		:href="url"
		@click="handleClick"
	>
		<div
			class="search-suggest__item-header search-suggest__item"
			aria-hidden="true"
		>
			<FaIcon
				:icon="getFaIcon(item?.type)"
				class="search-suggest__icons"
			/>
			<div class="search-suggest__text-container">
				<span
					class="search-suggest__title-word"
					v-html="item.title"
				>
				</span>
				<div class="search-suggest__title-word--additional">
					<small v-if="item.subtitle">{{ removeBoldTags(item?.subtitle, ' ') }}</small>
				</div>
			</div>
		</div>
	</a>
</template>

<script setup lang="ts">
import FaIcon from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';
import type { FontAwesomeIcon } from '@lmt-rpb/Atoms/FaIcon/FaIcon.vue';

const getFaIcon = (t: string): FontAwesomeIcon => {
	const type = (
		{
			countries: 'location',
			regions: 'location',
			cities: 'location',
			hotels: 'home',
			shops: 'store',
			pages: 'pages',
			topicpages: 'pages',
			regiongroups: 'location',
			countryhotels: 'location',
			regionhotels: 'location',
			cityhotels: 'location',
		} as { [key: string]: string }
	)[t];
	let icon: string = '';
	if (type === 'location') {
		icon = 'location-dot';
	} else if (type === 'pages') {
		icon = 'page';
	} else if (type === 'store') {
		icon = 'shop';
	} else if (type === 'home') {
		icon = 'hotel';
	}
	return ['fa-regular', `fa-${icon}`];
};

interface Props {
	url: string;
	item: {
		type: string;
		title: string;
		url: string;
		subtitle: string;
	};
}
const emit = defineEmits(['SearchSuggestItem:Loading']);

withDefaults(defineProps<Props>(), {});

const handleClick = (event: MouseEvent | KeyboardEvent) => {
	if (!event.ctrlKey && !event.altKey && !event.shiftKey && !event.metaKey) {
		emit('SearchSuggestItem:Loading');
	}
};

const removeBoldTags = (item: string, replaceValue: string): string => item.replace(/<b>/g, replaceValue).replace(/<\/b>/g, replaceValue);
</script>

<style lang="scss" scoped>
:deep(b) {
	color: $color-primary;
	font-weight: $font-weight-bold;
}

@mixin active-focus-styles {
	background: $color-destion-field-active-background;
	color: $color-white;

	.search-suggest__title-word,
	.search-suggest__icons,
	.search-suggest__title-word--additional {
		color: $color-white !important;
		fill: $color-white !important;

		:deep(.icon-location),
		:deep(.icon-pages),
		:deep(.icon-home),
		:deep(.icon-store) {
			stroke: $color-white;
			fill: none;

			&.icon-home,
			&.icon-store {
				fill: none !important;
			}
		}
	}
}

.search-suggest {
	&__item-header {
		margin: 0 0 1rem;
		padding-left: 3rem;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
	}

	&__item {
		width: 100%;
		padding: 1.5rem 1.5rem 1.5rem 1rem;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		text-decoration: none;
		display: flex;

		&-link {
			text-decoration: none;
			height: 6rem;
			display: block;
		}

		&:hover {
			background: $color-primary-l6;
			color: $color-text-l24;
		}

		&:active,
		&:focus {
			@include active-focus-styles;

			& :deep(b) {
				color: $color-white;
			}
		}
	}

	@media (min-width: $breakpoint-medium) {
		.is-active {
			.search-suggest__item {
				@include active-focus-styles;

				& :deep(b) {
					color: $color-white;
				}
			}
		}
	}

	&__icons {
		font-size: $font-large-4;
		margin: 0.4rem 0.8rem 0;
		min-width: 3rem;
	}

	&__text-container {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__title-word {
		color: $color-black;

		&--additional {
			display: flex;
			font-size: $font-small-3;
			color: $color-placeholder-text;
			font-weight: $font-weight-regular;
			min-height: 1.7rem;

			& small {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>

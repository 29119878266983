<template>
	<div
		class="search-suggest"
		@scroll.capture="focusOut"
	>
		<DropdownFilterType
			ref="dropdown"
			:title="''"
			tabindex="-1"
			class="autocomplete__dropdown"
			:searchable="true"
			:placeholder="placeholderText"
			:modal-header="'Webseite durchsuchen'"
			:icon="icon"
			:allow-clear="true"
			apply-button-text="Übernehmen"
			:search-term="term"
			:with-menu-header="false"
			:emit-on-ok="true"
			:is-focused="isFocused"
			:show-toggle-icon="false"
			:is-custom-focus="false"
			:dropdown-visible="minCharCount"
			:is-custom-view-port="'isMinTablet'"
			:custom-clear-icon="customClearIcon"
			@DropdownFilterType:clear="clearSuggestAndClose"
			@DropdownFilterType:abort="clearSuggestAndClose"
			@DropdownFilterType:KeyEnter="selectFirst"
			@update:searchTerm="onInput"
			@DropdownFilterType:focusedOut="focusOut"
			@DropdownFilterType:focusedIn="focusIn"
		>
			<div
				ref="itemListContainer"
				class="search-suggest__list"
				role="group"
				aria-label="Ergebnisse"
				tabindex="-1"
			>
				<!-- Loading -->

				<template v-if="loading">
					<!-- Loading -->
					<slot name="loading">
						<div class="search-suggest__load-box">
							<Loading
								size="small"
								class="search-suggest__loader"
							/>
						</div>
					</slot>
				</template>

				<!-- Error -->
				<template v-else-if="error">
					<!-- Error -->
					<slot
						name="error"
						:error="error"
						:term="term"
					>
						<div class="search-suggest__helper">
							<p>Der Server ist im Augenblick nicht erreichbar.</p>
							Bitte versuchen Sie es in Kürze erneut.
						</div>
					</slot>
				</template>
				<template v-else>
					<template v-if="!searchSuggests.length">
						<!-- No result -->
						<slot name="no-result">
							<div class="search-suggest__helper">Keine Ergebnisse gefunden.</div>
						</slot>
					</template>
					<template v-else>
						<slot name="result">
							<!-- Result list -->
							<ul
								class="search-suggest__item-list"
								:class="{ 'search-suggest__item-list--loading': loadingOnClick }"
							>
								<div
									v-if="loadingOnClick"
									class="search-suggest__load-box-suggest"
								>
									<Loading
										size="small"
										class="search-suggest__loader"
									/>
								</div>

								<!-- Result item -->
								<SearchSuggestItem
									v-for="(item, key) in searchSuggests"
									:key="key"
									ref="searchSuggestItemList"
									:class="{ 'is-active': key === 0 }"
									:url="buildUrl(item.url)"
									:item="item"
									@SearchSuggestItem:Loading="loadingOnClick = true"
								/>
							</ul>
						</slot>
					</template>
				</template>
			</div>
		</DropdownFilterType>
	</div>
</template>

<script lang="ts" setup>
import Loading from '@lmt-rpb/Loading/Loading.vue';
import { computed, onBeforeUnmount, ref, onBeforeMount } from 'vue';
import { useStore } from '@/components/common/store';
import * as searchSuggestService from '@services/searchSuggestService';
import type { SuggestionElements } from '@/interfaces/api/v1-search-suggest';
import DropdownFilterType from '@lmt-rpb/DropdownFilterType/DropdownFilterType.vue';
import { storeToRefs } from 'pinia';
import { useBreakpointStore } from '../../../../store/breakpointsStore';
import SearchSuggestItem from '@lmt-rpb/SearchSuggestItem/SearchSuggestItem.vue';

const store = useStore();

const { isMinTablet: isViewPort } = storeToRefs(useBreakpointStore());

interface Props {
	icon?: string;
	customClearIcon?: string;
}

const isFocused = ref(false);
const focusIn = () => (isFocused.value = true);
const focusOut = () => {
	dropdown.value?.modal?.input?.blur();
	dropdown.value?.formField?.input?.blur();
	isFocused.value = false;
};

withDefaults(defineProps<Props>(), {
	icon: '',
	customClearIcon: undefined,
});

function selectFirst() {
	const url = searchSuggests.value[0]?.url;
	if (url) {
		loadingOnClick.value = true;
		window.location.href = buildUrl(url);
	}
}

const dropdown = ref<InstanceType<typeof DropdownFilterType> | null>(null);

const searchSuggestItemList = ref<InstanceType<typeof SearchSuggestItem>[]>([]);

const term = ref<string>('');

const emptyState = [
	{
		type: '',
		title: '',
		url: '',
		subtitle: '',
	},
];

const searchSuggests = ref<SuggestionElements[]>(emptyState);

const loading = ref(false);

const loadingOnClick = ref(false);

const error = ref<boolean>(false);

const itemListContainer = ref<HTMLElement>();

const input = ref<HTMLInputElement>();

const placeholderText = computed((): string => (isFocused.value ? '' : isViewPort.value ? 'Suchen' : 'Website durchsuchen'));

const baseUrl = computed((): string => store.state.config.baseUrl);

const buildUrl = (urlParams: string) => `${baseUrl.value}${urlParams}?term=${encodeURIComponent(term.value)}`;

const minCharCount = computed((): boolean => !!term.value && term.value.length >= 2);

const search = async () => {
	loading.value = true;
	try {
		searchSuggests.value = await searchSuggestService.get(term.value);
	} catch (err) {
		console.error(err);
		error.value = true;
	} finally {
		loading.value = false;
	}
};

const clearSuggestAndClose = (): void => {
	term.value = '';
	searchSuggests.value = [];
};

const onTermChange = (): void => {
	error.value = false;
	if (!minCharCount.value) {
		searchSuggests.value = emptyState;
	} else {
		search();
	}
};

const onInput = (value: string): void => {
	term.value = value;
	onTermChange();
};
const closeModal = () => {
	if (!isViewPort.value) {
		return;
	}
	dropdown.value?.close();
};
const observer = new MutationObserver((mutations) => {
	mutations
		.filter((mutation) => mutation.type === 'attributes')
		.forEach((mutation) => {
			if (mutation.target?.className?.includes('--hidden')) {
				closeModal();
				focusOut();
			}
		});
});

const onUnload = () => {
	loadingOnClick.value = false;
	closeModal();
	focusOut();
};

onBeforeMount(() => {
	observer.observe(document.body, { attributes: true });
	window.addEventListener('unload', onUnload);
	window.addEventListener('pagehide', onUnload);
});
onBeforeUnmount((): void => {
	observer.disconnect();
	window.addEventListener('unload', onUnload);
	window.addEventListener('pagehide', onUnload);
});
defineExpose({
	input,
	dropdown,
	clearSuggestAndClose,
	term,
	search,
	searchSuggests,
});
</script>

<style lang="scss" scoped>
:deep(.search-modal__main.is-focused) {
	max-height: calc(100% - 10rem);
}

:deep(.form-field__icon) {
	margin-left: 0.5rem;
}

:deep(.form-field) {
	@media (min-width: $breakpoint-small) {
		height: 5.6rem;
	}
}

:deep(.form-field__input) {
	&::placeholder {
		font-weight: $font-weight-semibold;
	}
}

:deep(.dropdown__box) {
	width: 100%;

	@media (min-width: $breakpoint-small) {
		width: 39rem !important;
	}

	z-index: 5001 !important; // filter has 5000
	margin-top: 0;
	left: 0 !important;

	@media (min-width: $breakpoint-small) {
		margin-top: 1rem;
	}

	@include customScrollbar($color-primary, true, 0.8rem);
}

:deep(.dropdown__inner) {
	overflow: auto;
}

@media (min-width: $breakpoint-small) {
	:deep(.dropdown__inner) {
		border-top-right-radius: 1rem;
	}
}

@media (min-width: $breakpoint-medium) {
	:deep(.dropdown__inner) {
		border-top-right-radius: 0;
	}

	:deep(.dropdown.is-open .dropdown__box) {
		width: 100%;
		left: 0;
	}
}

:deep(.dropdown .dropdown__container) {
	padding-bottom: 0.4rem;
	overflow: hidden;
}

@media (min-width: $breakpoint-extralarge) {
	:deep(.dropdown.is-open .dropdown__box) {
		height: auto;
		max-height: 75rem;
	}

	:deep(.dropdown .dropdown__container) {
		height: auto;
		max-height: 75rem;
	}
}

.search-suggest {
	width: 100%;

	@media (min-width: $breakpoint-small) {
		width: 27rem;
	}

	@media (min-width: $breakpoint-medium) {
		width: 39rem;
	}

	&__title-word--match {
		color: $color-primary;
		font-weight: $font-weight-bold;
	}

	&__title-word {
		color: $color-black;

		&--additional {
			display: flex;
			font-size: $font-small-3;
			color: $color-placeholder-text;
			font-weight: $font-weight-regular;
		}
	}

	&__helper {
		padding: 1rem 2rem 2rem;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;

		@media (min-width: $breakpoint-small) {
			padding: 1rem 5rem 2rem;
		}
	}

	&__load-box {
		position: relative;
		padding: 3.75rem 2rem;
	}

	&__load-box-suggest {
		z-index: 9999;
		opacity: 1;
	}

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-75%, -50%, 0);
	}

	&__list {
		overflow: auto;
		min-height: calc(100% + 0.5rem);
	}

	&__item {
		padding-left: 0.2rem;

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;

			&--loading {
				opacity: 0.4;
			}
		}
	}

	&__empty-item {
		padding: 1.5rem 1.5rem 1.5rem 4.5rem;
	}
}
</style>

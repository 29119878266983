import { createApp } from 'vue';
import Usp from '@lmt-rpb/Usp/Usp.vue';
import { USPData } from '@/interfaces/usp-data-type';
import { mountReplace } from '../utils/mountUtils';

const initUSP = () => {
	document.querySelectorAll('.usp-mountpoint').forEach((elementToMount) => {
		const uspDataString = (elementToMount as HTMLElement)?.dataset?.usp;
		const title = (elementToMount as HTMLElement)?.dataset?.header;
		const subtitle = (elementToMount as HTMLElement)?.dataset?.subheader;
		const headerLayout = (elementToMount as HTMLElement)?.dataset?.headerlayout;
		const headerLink = (elementToMount as HTMLElement)?.dataset?.headerlink;
		if (!uspDataString) return;
		const uspData: USPData[] = JSON.parse(uspDataString);

		const app = createApp(
			Usp,
			{
				uspData,
				title,
				subtitle,
				headerLayout,
				headerLink
			}
		);
		mountReplace(app, elementToMount);
	});
};

initUSP();
